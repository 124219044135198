.Footer {
  width: 100vw;
  height: 100vh;
  background-position: center;
  background-size: cover;

  .content {
    width: 100%;
    height: 100%;
    background-color: #0003;
    padding: 5vw 5vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    p {
      color: white;
      font-family: "Amatic SC", cursive;
      font-size: 8rem;
      line-height: 7rem;
      text-align: center;

      @media screen and (max-width: 500px) {
        font-size: 4rem;
        line-height: 3.5rem;
      }
    }
  }
}
