.ImageCarousel {
  height: 100vh;
  padding: 4vw 4vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .PausedView {
    width: 50vw;
    height: 50vw;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    align-self: center;
    border-radius: 50%;
    background-size: cover;
    background-position: center;
    background-color: black;
    cursor: grab;
    animation: rotate 5s ease-in-out infinite alternate;

    .content {
      color: white;
      font-size: 25vw;
      font-family: "Amatic SC", cursive;
    }

    @media screen and (max-width: 500px) {
      width: 80vw;
      height: 80vw;
    }

    @keyframes rotate {
      from {
        transform: rotate(-20deg);
      }
      to {
        transform: rotate(20deg);
      }
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

    .carousel {
      max-width: 1280px;

      .carousel-item {
        img {
          object-fit: cover;
          object-position: center;
          width: 100%;
        }
      }
    }

    .textContainer {
      display: flex;
      flex-direction: row;
      align-items: flex-end;

      & > * {
        margin: unset;
      }
    }

    @media screen and (max-width: 600px) {
      padding: unset;
      max-width: unset;

      .textContainer {
        padding: 0 0.2em;
        flex-direction: column;
        align-items: flex-start;
      }
    }
  }

  .arrow-container {
    display: flex;
    align-items: center;
    animation: point-right-down linear 5s infinite;

    p {
      font-family: "Amatic SC", cursive;
      font-size: 2.5rem;
      margin-right: 1rem;
    }

    .arrow-down {
      g {
        stroke: black;
        stroke-width: 1px;
      }
    }
  }

  @keyframes point-right-down {
    0% {
      opacity: 0;
    }
    10% {
      opacity: 1;
    }
    60% {
      transform: translate(15vw, 0);
      opacity: 1;
    }
    100% {
      transform: translate(15vw, 150%);
      opacity: 0;
    }

  }
}
