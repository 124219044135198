.Header {
  position: relative;
  width: 100vw;
  height: 100vh;
  background-size: cover;

  .text-container {
    padding: 6vw 3vh;
    color: white;
  }

  img {
    position: absolute;
    bottom: 0;
    right: 0;
    transform-origin: bottom right;

    &.nola {
      max-height: 100vh;
      max-width: 110vw;
      animation: fly 2s ease-in-out infinite alternate;
    }

    &.clouds {
      max-height: 100vh;
      max-width: 110vw;
      animation: pulse 1.5s ease-in-out infinite alternate-reverse;
    }
  }

  .button-container {
    position: absolute;
    left: 15vw;
    bottom: 4rem;
    padding: 1rem;
    animation: point-down 2s linear infinite;
    display: flex;
    align-items: center;
    flex-direction: column;

    p {
      width: 100%;
      color: white;
      margin: 0;
      text-align: center;
    }

    @media screen and (max-width: 500px) {
      left: 4vw;

      h3 {
        font-size: 2.5rem;
      }
    }
  }

  @keyframes point-down {
    0% {
      opacity: 0;
    }

    20% {
      opacity: 1;
    }

    100% {
      transform: translateY(100%);
    }
  }

  @keyframes fly {
    to {
      transform: translate(-1rem, -1rem) scale(1.05);
    }
  }

  @keyframes pulse {
    from {
      transform: scale(1);
    }
    to {
      transform: scale(1.05);
    }
  }

}
