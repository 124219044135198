.App {

  h1 {
    font-size: 10vmax;

    @media screen and(max-width: 500px) {
      font-size: 4.5rem;
    }
  }

  h3 {
    font-size: 3rem;
    font-family: 'Amatic SC', cursive;
    color: white;
  }

  svg {
    pointer-events: none;
  }

  a {
    color: #4bc1c8;
  }
}

